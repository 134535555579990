import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Process = makeShortcode("Process");
const Flash = makeShortcode("Flash");
const Card = makeShortcode("Card");
const List = makeShortcode("List");
const Link = makeShortcode("Link");
const AudioPlayer = makeShortcode("AudioPlayer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-learn-how-to-learn",
      "style": {
        "position": "relative"
      }
    }}>{`🎓 Learn how to learn`}<a parentName="h1" {...{
        "href": "#-learn-how-to-learn",
        "aria-label": " learn how to learn permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`If `}<a parentName="p" {...{
        "href": "/learn/module-1/value/"
      }}>{`"What is value?"`}</a>{` was the ultimate question, then this is the holy
grail. We'll use the same pattern of thought we've been building up to try and get a foothold... `}</p>
    <p>{`First, we must understand what learning is not. It is not the acquisition of knowledge -
that is properly called intellect. It is not the ability to memorize content and pass an
assessment - that is called regurgitation and has the same relationship to real learning
as that between how young birds are fed and the moment they are ejected from the nest
and must either fly or die.`}</p>
    <p>{`Learning how to learn is a process with very specific characteristics. It requires `}</p>
    <Process mdxType="Process">
    
a sincere desire to know;   
      <p>{`a determination to practice what you know consistently;  `}</p>
      <p>{`a passion that will not let you stop until you know;  `}</p>
      <p>{`and a heart clear enough to know its own `}<a parentName="p" {...{
          "href": "/learn/module-3/intention/"
        }}>{`intention`}</a>{` for pursuing knowledge at all.`}</p>
    </Process>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: Learning is not the acquisition of knowledge. It is a process powered by what four characteristics?`}</p>
        <p>{`A sincere desire to know; a determination to practice; passion; a clear heart.`}</p>
      </Card>
    </Flash>
    <p>{`Unless you're burning to find the answer, and unless you're willing to give up everything
in the pursuit of that answer, you will never truly learn it. Learning how to learn is not something which can be taught in the usual sense. All we can do is reflect honestly with one another in order to raise awareness and cultivate presence. When you are truly present, and that which illumines the `}<a parentName="p" {...{
        "href": "/learn/module-0/play-of-pattern/#the-honest-question"
      }}>{`unutterable question`}</a>{` you are shines through, you will be able to follow its trail without hesitation.`}</p>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=3HYlbg6RKMA&t=73s"
      }}>{`secret fire`}</a>{` at the core of your being might have nothing to do with building a better web: you must still be honest enough to follow it. Ultimately, learning how to learn is a practice built on a
deep `}<a parentName="p" {...{
        "href": "/learn/module-0/trust/"
      }}>{`trust`}</a>{` in what we could call the underlying pattern. You have to
trust that you will come to know what you need to know when you need to know it. This trust creates
the necessary neurological conditions for `}<strong parentName="p">{`useful knowledge`}</strong>{` to take `}<a parentName="p" {...{
        "href": "/learn/module-1/meaning/#further-references"
      }}>{`root`}</a>{`.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: Truly learning how to learn is not just an intellectual process. We have to let it happen by cultivating what?`}</p>
        <p>{`Constant awareness.`}</p>
      </Card>
    </Flash>
    <p>{`The rest is practice. Practice, practice, practice - until you realise that there is no
difference between practice and perfection. That what you most wanted to know was here all
along, right with you - closer than your jugular vein - and all that work and struggle and
acquisition and desire and practice and letting go was just to bring you back into this moment,
right now. `}</p>
    <p>{`There is nothing else to learn. Nothing other to know.`}</p>
    <List mdxType="List">
      <Link to="https://brandnewbox.com/inthestacks/" mdxType="Link">
        <p>{`"You can learn anything, the voice said. You can do whatever you want, if you do it every Wednesday, and clean up after yourself, and ask for help when you need it."`}</p>
      </Link>
    </List>
    <h2 {...{
      "id": "a-surprisingly-educated-public",
      "style": {
        "position": "relative"
      }
    }}>{`A Surprisingly Educated Public`}<a parentName="h2" {...{
        "href": "#a-surprisingly-educated-public",
        "aria-label": "a surprisingly educated public permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <blockquote>
      <p parentName="blockquote">{`"The current search for new educational funnels must be reversed into the search for their institutional inverse: `}<strong parentName="p">{`educational webs`}</strong>{` which heighten the opportunity for each one to transform each moment of living into one of learning, sharing, and caring."–Ivan Illich`}</p>
    </blockquote>
    <p>{`As is often the case in Kernel, the primary focus is you. `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=QHKrXUHXmO4"
      }}>{`You are the subject`}</a>{`. When this knowing-being is stabilised in your heart, then the environments you find yourself in all serve as means to direct you home into the silent awareness which grounds all real learning. `}</p>
    <p>{`Learning about reality always implies some collective element, which is mirrored by and in each heart. To understand how such knowledge may be transmitted within educational systems, we turn once more to Ivan Illich and an essay called `}<a parentName="p" {...{
        "href": "https://download.tuxfamily.org/openmathdep/history/Celebration_of_Awareness-Illich.pdf"
      }}>{`School: The Sacred Cow`}</a>{` on pages 52-58 of the linked copy of `}<em parentName="p">{`Celebration of Awareness`}</em>{`.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Gradually, the idea grew that schooling was a necessary means of becoming a useful member of society. It is the task of this generation to bury that myth."`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`"This will be a difficult task. Schooling has developed a formidable folklore. The begowned academic professors who we have witnessed today evoke the ancient procession of clerics and little angels on the day of Corpus Christi. The Church, holy, catholic, apostolic, is rivalled by the school, accredited, compulsory, untouchable, universal."`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`"Participation in a 'production system', of no matter what kind, has always threatened the prophetic function of the Church as it now threatens the educational function of the school."`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`"The radical deschooling of society begins, therefore, with the unmasking by cultural revolutionaries of the myth of schooling. It continues with the struggle to liberate other minds from the `}<a parentName="p" {...{
          "href": "/learn/module-7/no-paradigm/#re-cognition"
        }}>{`false ideology`}</a>{` of schooling - an ideology which makes domestication by schooling inevitable. In its final and positive stage it is the struggle for the right to `}<a parentName="p" {...{
          "href": "/build/token-studies/free-learn/"
        }}>{`educational freedom`}</a>{`."`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`"The basic purpose of public education should be to create a situation in which society obliges each individual to `}<a parentName="p" {...{
          "href": "/conversation/true-voice"
        }}>{`take stock of himself and his poverty`}</a>{`. Education implies a growth of an independent sense of life and a `}<a parentName="p" {...{
          "href": "/conversation/reciprocity"
        }}>{`relatedness`}</a>{` which go hand in hand with increased access to, and use of, `}<a parentName="p" {...{
          "href": "/learn/module-3/remember"
        }}>{`memories`}</a>{` stored in the `}<a parentName="p" {...{
          "href": "/conversation/hospitality/#playful-souls"
        }}>{`human community`}</a>{`."`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`"This presupposes a place within the society in which each of us is awakened by surprise; a place of encounter in which others surprise me with their liberty and make me aware of my own." `}</p>
    </blockquote>
    <p>{`Such awakening resonates with two other deep notes struck in this course. In `}<a parentName="p" {...{
        "href": "/build/games/infinite"
      }}>{`Finite and Infinite Games`}</a>{`, James P. Carse makes the distinction between being trained to win, and learning how to continue the play with ongoing, dramatic reinterpretation:`}</p>
    <blockquote>
      <p parentName="blockquote">{`"To be prepared against surprise is to be trained. To be prepared for surprise is to be educated."`}</p>
    </blockquote>
    <p>{`Surprise, or unexpectedness, is also the measure Illich's contemporary Claude Shannon used to define "bits" of information in his `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=tPcCElmAaRI&list=PL_NAkUK835PWbclYgtIJ07aXSPx-cWper&index=11"
      }}>{`mathematical theory of communication`}</a>{`. If you enjoy being formally surprised, you may like `}<a parentName="p" {...{
        "href": "https://arxiv.org/pdf/0812.4360.pdf"
      }}>{`this paper`}</a>{`, especially Section 2.6 on page 8, followed by a reference to Bayesian surprise on page 13. `}</p>
    <blockquote>
      <p parentName="blockquote">{`"My friends, it is your task to surprise yourselves, and us, with the education you succeed in inventing for your children. Our hope of salvation lies in our being surprised by the Other. Let us learn always to `}<a parentName="p" {...{
          "href": "/learn/module-7/giving/#receiving-gracefully"
        }}>{`receive`}</a>{` further surprises."`}</p>
    </blockquote>
    <h2 {...{
      "id": "listen",
      "style": {
        "position": "relative"
      }
    }}>{`Listen`}<a parentName="h2" {...{
        "href": "#listen",
        "aria-label": "listen permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <AudioPlayer src="https://kernel-audio.s3.amazonaws.com/M6E2+-+Learning.mp3" mdxType="AudioPlayer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      